/* Global container for the app */
.app-container {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f4f4f9; /* Light background for better contrast */
  color: rgb(19, 41, 63);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all content horizontally */
  padding: 20px;
}

/* Max width and centering for content */
.content-wrapper {
  width: 100%;
  padding-top: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Adjustments for larger screens */
@media (min-width: 768px) {
  .content-wrapper {
    /* padding: 0 40px; */
  }
}

/* Link styling */
a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* General button styling */
button {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* General dropdown styling */
.menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-container .dropdown {
  position: relative;
  display: inline-block;
}

.menu-container .dropdown .dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.menu-container .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 10px;
}

.menu-container .dropdown:hover .dropdown-content {
  display: block;
}

.menu-container .dropdown-content .dropdown-item {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.menu-container .dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
}
