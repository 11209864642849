/* Import Battambang font */
@import url('https://fonts.googleapis.com/css2?family=Battambang:wght@400;700&display=swap');

/* Container for each sentence */
.sentence-container {
  margin-bottom: 20px;
  padding: 15px; /* Reduced padding */
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 100%; /* Take full screen width */
  box-sizing: border-box; /* Ensure padding is included in the total width */
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Header section for sentence (play button and text) */
.sentence-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.play-button {
  width: 35px; /* Smaller size */
  height: 35px;
  background-color: #4CAF50;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.play-icon {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid white;
}

.sentence-english {
  font-size: 16px; /* Slightly smaller */
  color: rgb(19, 41, 63);
  font-weight: 500;
}

/* Container for the word cards */
.words-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Reduced space between word cards */
  width: 100%;
  justify-content: flex-start;
}

/* Individual word card */
.word-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  display: inline-block;  /* Allow the card to resize based on content */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
  transition: transform 0.2s;
  cursor: pointer;
  white-space: nowrap; /* Prevent wrapping inside the card */
}

.word-card:hover {
  transform: scale(1.05);
}

/* Text for Khmer script in word card */
.word-kh {
  font-family: 'Battambang', sans-serif;
  font-size: 24px; /* Slightly smaller Khmer text */
  font-weight: bold;
  color: rgb(28, 48, 72);
}

/* Ensure no horizontal overflow on mobile devices */
@media screen and (max-width: 600px) {
  .sentence-container {
    padding: 10px; /* Reduce padding on smaller screens */
  }
  
  .word-card {
    min-width: 40px; /* Smaller cards on mobile */
    max-width: 80px;
  }

  .word-kh {
    font-size: 20px; /* Adjust font size for small screens */
  }
}
