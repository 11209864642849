/* Header container */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white; /* Clean white background */
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo and title styling */
.logo-container {
  display: flex;
  align-items: center;
}

.khmer-flag {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.header-title {
  font-size: 20px; /* Smaller title */
  font-weight: bold;
  color: rgb(19, 41, 63);
}

.menu-container {
  display: flex;
  align-items: center;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .header-title {
    font-size: 18px; /* Smaller font on mobile */
  }
  .khmer-flag {
    width: 40px;
  }
}

